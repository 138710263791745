<template lang="pug">
.filters
  .row.justify-content-between.m-t-5.m-b-15
    .col-md-9.col-sm-6.col-xs-12
      .d-flex.align-items-center
        AppAddNewButton(
          title="organization_management.organization_list.add_new_organization"
          @click="openNewOrganizationModal"
        )
        AppSearch(
          :value="filters['searchValue']"
          @update="applyFilter('searchValue', $event)"
        )
    .col-md-3.col-sm-6.col-xs-12.text-right
      AppDownloadButton(disabled)
</template>

<script>
  // mixins
  import withModal from "@/mixins/withModal"

  // components
  import OrganizationForm from "./OrganizationForm"

  export default {
    props: {
      filters: Object
    },

    components: {
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppDownloadButton: () => import("@/components/elements/AppButton/WithIcon/Download"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew"),
      AppSearch: () => import("@/components/elements/AppSearch")
    },

    mixins: [withModal],

    methods: {
      applyFilter(itemType, items) {
        const filters = { ...this.filters, [itemType]: items }
        this.$emit("change-filters", filters)
      },

      openNewOrganizationModal() {
        this.$openModal({
          component: OrganizationForm,
          size: "large",
          title: this.$t("organization_management.organization_list.create_organization")
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .filters
    ::v-deep
      .app-search
        width: 100%
        margin-left: 10px
        max-width: 300px

  .filter-title
    color: $filter-title-color
    font-size: 0.8rem
    height: 18px
    margin-bottom: 5px

  .app-select
    margin: 0
    padding: 0
</style>
